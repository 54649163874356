import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { English } from "./English"
import { French } from "./French"


i18next.use(LanguageDetector).use(initReactI18next).init({
    debug:true,
    fallbackLng:"Fr",
    resources:{
        Fr: French,
        en: English,
    },
    interpolation:{escapevalue: false}
})


export default i18next