import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Modal,
  Typography,
  TextField,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";
import logo from "../../src/Assets/Images/Logo.webp";
import lang from "../../src/Assets/Images/LanguageIcons.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg")); // Check if screen width is <= 768px

  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code);
    localStorage.setItem("i18nextLng",code)
    handleLangClose();
  };
  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  const handleModalToggle = () => {
    setOpenModal(!openModal);
  };

  const handleLangClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    { text: `${t("footer.menu1.subHead1")}`, id: "home" },
    { text: `${t("footer.menu1.subHead2")}`, id: "features" },
    { text: `${t("footer.menu1.subHead3")}`, id: "services" },
    { text: `${t("footer.menu1.subHead5")}`, id: "become-driver" },
    { text: `${t("footer.menu2.subHead1")}`, id: "about" },
    { text: `${t("footer.menu2.subHead2")}`, id: "contact" },
  ];
  const handleMenuClick = () => {
    if (location.pathname === "/faq" || location.pathname === "/privacypolicy") {
      navigate("/");
    } 
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
       try {
        const formData = new FormData()
        formData.append("name" , values.name)
        formData.append("email" , values.email)
        formData.append("message" , values.message)
        const response = await AxiosInstance.post(APILIST.CONTACT_US_POST,formData)
        if (response && response.data.s === 1) {
          toast.success("Submitted Sucessfully")
          resetForm()
        }
       } catch (error) {
        
       }
    },
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#ffffff20",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "999",
          transition: "all 0.3s ease-in-out",
          boxShadow: !scroll
            ? "0 0 0px rgba(0,0,0,0)"
            : "0 0 10px rgba(0,0,0,0.1)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, md: 8 },
            py: 2,
            background: "transparent",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "200px" } }}>
            <img src={logo} alt="logo" />
          </Box>

          {isMobile ? (
            <>
              <Box
                sx={{
                  width: { xs: "120px", sm: "150px" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={lang}
                  alt="language"
                  style={{ width: "24px", cursor: "pointer" }}
                  onClick={handleLangClick}
                />
                <IconButton
                  sx={{
                    background: "black",
                    "&:hover": { background: "black" },
                  }}
                  onClick={handleMenuToggle}
                >
                  <MenuIcon
                    sx={{
                      fontSize: { xs: "28px", sm: "32px" },
                      color: "white",
                    }}
                  />
                </IconButton>
              </Box>
              <Drawer anchor="right" open={openMenu} onClose={handleMenuToggle}>
                <Box sx={{width:"100%",p:2}}>
                  <img src={logo} style={{width:"150px"}} alt="" />
                </Box>
                <List sx={{ width: { xs: "200px", sm: "300px" },fontSize:"22px" }}>
                  {menuItems.map((item, index) => (
                    <ListItem key={index} sx={{
                      ".css-10hburv-MuiTypography-root" : {fontSize:"20px"}
                    }}  onClick={handleMenuToggle}>
                      <Link
                        to={item.id}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={
                          item.text === `${t("footer.menu2.subHead2")}`
                            ? handleModalToggle
                            : ()=>handleMenuClick(item.id)
                        }
                      >
                        <ListItemText  primary={`${item.text}`} />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", md: "100%", lg: "900px" },
              }}
            >
              <ul
                className="navul"
                style={{
                  listStyle: "none",
                  display: "flex",
                  margin: 0,
                  padding: 0,
                }}
              >
                {menuItems.map((item, index) => (
                  <li
                    className="navli"
                    key={index}
                    style={{ margin: "0 10px" }}
                  >
                    <Link
                      to={item.id}
                      smooth={true}
                      duration={500}
                      offset={-100}
                      onClick={
                        item.text === `${t("footer.menu2.subHead2")}`
                          ? handleModalToggle
                          : ()=>handleMenuClick(item.id)
                      }
                    >
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
              <img
                src={lang}
                alt="language"
                style={{ width: "24px", cursor: "pointer" }}
                onClick={handleLangClick}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLangClose}
      >
        <MenuItem onClick={() => handleChangeLanguage("en")}>English</MenuItem>
        <MenuItem onClick={() => handleChangeLanguage("Fr")}>French</MenuItem>
      </Menu>

      <Modal open={openModal} onClose={handleModalToggle}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 330, sm: 400, md: 600 },
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            gap: { xs: 1.5, md: 2 },
            border: "none",
            outline: "none",
            height: { xs: "350px", md: "500px" },
            overflow: "scroll",
          }}
        >
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={handleModalToggle}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontSize: { xs: "22px", sm: "24px", md: "38px" } }}
          >
            {t("modal.head")}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "20px" },
              color: "#000000B2",
            }}
          >
            {t("modal.subHead")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <TextField
              name="name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Name")}
              sx={{
                outline:
                  formik.touched.name && formik.errors.name
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.name}
              </Typography>
            ) : null}
            <TextField
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Email")}
              sx={{
                outline:
                  formik.touched.email && formik.errors.email
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.email}
              </Typography>
            ) : null}
            <TextField
              name="message"
              value={formik.values.message}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={t("modal.Message")}
              multiline
              rows={5}
              sx={{
                outline:
                  formik.touched.message && formik.errors.message
                    ? "1.5px solid red"
                    : "none",
                width: "100%",
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.message && formik.errors.message ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.message}
              </Typography>
            ) : null}
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  background: "#142328",
                  borderRadius: "12px",
                  p: 1,
                  mt: 2,
                  width: "250px",
                  "&:hover": { background: "#142328" },
                  textTransform: "none",
                }}
              >
                {t("modal.button")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
