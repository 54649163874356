import playStore from "../Assets/Images/GooglePlayFrench.png";
import appStore from "../Assets/Images/AppStoreFrench.svg";
export const home = {
  head: "Vous voulez une Livraison rapide, avez vous besoin de vous deplacer a votre guise en toute securité a moindre cout et en un seul click!",
  subHead:
    "Découvrez YANDI L'Appli Tout! En-un pour vos livraison de repas ou de colis, pour vos déplacements !",
  playStore: playStore,
  appStore: appStore,
};

export const features = {
  head: "Fonctionalites",
  subHead:
    "Découvrez les principales fonctionnalités de YANDI, conçues pour simplifier et améliorer votre quotidien :",
  box1: {
    head: "Enregistrement simple",
    subHead: "Inscrivez-vous facilement etrapidement, sanstracas.",
  },
  box2: {
    head: "Suivi en temps réel",
    subHead:
      "Obtenez une vue instantanée des progrès et suivez les activités en direct.",
  },
  box3: {
    head: "Paiements sécurisés",
    subHead:
      "Assurez la protection de vos s transactions grâce à une sécurité renforcée.",
  },
  box4: {
    head: "Interface conviviale",
    subHead: "Profitez d'une conception claire et facile à utiliser.",
  },
};

export const services = {
  head: "Nos Services",
  subHead:
    "Des solutions complètes pour vos trajets, la livraison de nourriture et le transport de marchandises, accessibles facilement :",
  carRidingServices: {
    head: "Services d'auto-recours",
    subHead:
      "Réservez facilement vos trajets en choisissant parmi une variété de véhicules, incluant voitures, motos et tricycles.",
  },
  foodDeliveryServices: {
    head: "Services de livraison de nourritures",
    subHead:
      "Commandez vos plats préférés auprès des restaurants locaux et recevez-les rapidement et en toute fraîcheur.",
  },
  smallFreightTransportServices: {
    head: "Services de transport de marchandises ou de petits colis",
    subHead:
      "Expédiez vos marchandises ou vos petits colis en toute sécurité et rapidement avec des options adaptées à divers types de véhicules.",
  },
};

export const becomeADriver = {
  head: `Devenez un chauffeur YANDI, Gérez votre emploi du temps comme vous le souhaitez`,
  subHead:
    "Rejoignez YANDI en tant conducteur pour des missions de livraison de nourriture, de trajets en voiture ou de livraison de colis ou de marchandises. Bénéficiez d'horaires flexibles, de revenus attractifs et de la liberté de sélectionner vos trajets.",
};

export const about = {
  head: "À propos de YANDI",
  subHead:
    "Découvrez comment YANDI rend vos journées plus simples avec des services de livraison, de trajets et de transport fiables",
  content:
    "YANDI est une application tout-en-un qui offre des services fiables et efficaces pour la livraison de nourriture, les trajets en voiture et le transport de petits colis. Que vous ayez faim, besoin de vous déplacer ou d'envoyer un paquet, YANDI est là pour vous.",
};

export const experienceYandi = {
  head: "Êtes vous prêtes pour une expérience YANDI ?",
  subHead: "Téléchargez l'Appli",
};

export const footer = {
  logoSection: {
    content:
      "Découvrez comment YANDI rend vos journées plus simples avec des services de livraison, de trajets et de transport fiables",
    email: "information@yandiapp.com",
    contactNumber: "+224 620090162",
  },
  menu1: {
    head: "Menu",
    subHead1: "Accueil",
    subHead2: "Fonctionnalités",
    subHead3: "Services",
    subHead4: "Chauffeur",
    subHead5: "Devenez chauffeur",
  },
  menu2: {
    head: "Entreprise",
    subHead1: "À Propos",
    subHead2: "Contactez-nous",
    subHead3: "FAQ",
    subHead4: "politique de confidentialité",
  },
  menu3: {
    head: "Réseaux Sociaux",
  },
  copyRight: "Copyright © 2024 YANDI | Tous Droits Réservés",
};

export const modal = {
  head: "Contactez-nous",
  subHead:
    "Vous avez des questions ou besoin d'aide ? Notre équipe est là pour vous aider avec toutes vos demandes, demandes de support ou retours. Contactez-nous à tout moment, et nous vous répondrons rapidement.",
  Name: "Nom",
  Email: "Email",
  Message: "Message",
  button: "Envoyer",
};

export const French = {
  translation: {
    modal: modal,
    footer: footer,
    experienceYandi: experienceYandi,
    about: about,
    becomeADriver: becomeADriver,
    services: services,
    features: features,
    home: home,
  },
};
